import { Box, BoxProps, styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { withProfiler } from '@sentry/react';
import {
  BackgroundButtonwood,
  Ellipsis,
  LocalStorageProvider,
  ProtocolListProvider,
  ToastProvider,
  TokenListProvider,
  WalletConnectionProvider,
  Web3Provider,
  WrapperMapProvider,
} from 'common-client';
import { ConfigProvider, SUPPORTED_CHAINS } from 'config';
import { SubgraphProvider } from 'contexts/SubgraphContext';
import React, { lazy, Suspense, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { TopBarWrapper } from 'wrappers/TopBarWrapper';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ethereum: any;
  }
}

window.ethereum = window.ethereum || {};

const AppDiv = styled(Box, { name: 'AppDiv' })<BoxProps>(() => ({
  minHeight: '100vh',
  WebkitTapHighlightColor: 'transparent',
}));

const BodyBox = styled(Box, { name: 'BodyBox' })<BoxProps>(() => ({}));

const DynamicLoader = ({ component }: { component: string }) => {
  const LazyComponent = useMemo(() => lazy(() => import(`routes/${component}`)), [component]);
  return (
    <Suspense fallback={<Ellipsis />}>
      <LazyComponent />
    </Suspense>
  );
};

const queryClient = new QueryClient();

function App() {
  return (
    <LocalStorageProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <IntlProvider locale={navigator.language}>
          <Router>
            <Web3Provider>
              <WalletConnectionProvider supportedChains={SUPPORTED_CHAINS}>
                <QueryClientProvider client={queryClient}>
                  <TokenListProvider>
                    <WrapperMapProvider>
                      <ProtocolListProvider>
                        <ConfigProvider>
                          <SubgraphProvider>
                            <ToastProvider>
                              <AppDiv>
                                <BackgroundButtonwood />
                                <TopBarWrapper />
                                <BodyBox
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  flex={1}
                                >
                                  <Switch>
                                    <Route path="/auctions">
                                      <DynamicLoader component="AuctionsRoute" />
                                    </Route>
                                    <Route path="/auction/:auctionAddress">
                                      <DynamicLoader component="AuctionRoute" />
                                    </Route>
                                    <Route path="/">
                                      <Redirect to="/auctions" />
                                    </Route>
                                  </Switch>
                                </BodyBox>
                              </AppDiv>
                            </ToastProvider>
                          </SubgraphProvider>
                        </ConfigProvider>
                      </ProtocolListProvider>
                    </WrapperMapProvider>
                  </TokenListProvider>
                </QueryClientProvider>
              </WalletConnectionProvider>
            </Web3Provider>
          </Router>
        </IntlProvider>
      </LocalizationProvider>
    </LocalStorageProvider>
  );
}

export default withProfiler(App);
